<template>
  <v-card flat>
    <field-card
      v-if="customer && customer.upcoming_invoice"
      title="Upcoming Invoice"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            Next Payment
          </v-list-item-title>
          <v-list-item-subtitle>
            Period: {{ customer.upcoming_invoice.period_start | formatDate }} - {{ customer.upcoming_invoice.period_end | formatDate }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Payment Date: {{ customer.upcoming_invoice.next_payment_attempt | formatDate }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-title class="align-self-end">
            Invoice Subtotal: {{ customer.upcoming_invoice.subtotal | formatPrice }}
          </v-list-item-title>
          <v-list-item-title class="align-self-end">
            Vat (19%): {{ customer.upcoming_invoice.tax | formatPrice }}
          </v-list-item-title>
          <v-list-item-title class="align-self-end">
            Invoice Total: {{ customer.upcoming_invoice.total | formatPrice }}
          </v-list-item-title>
          <v-list-item-title v-if="customer.upcoming_invoice.starting_balance" class="align-self-end">
            Applied Balance: {{ customer.upcoming_invoice.starting_balance | formatPrice }}
          </v-list-item-title>
          <v-list-item-title class="font-weight-bold align-self-end">
            Amount Due: {{ customer.upcoming_invoice.amount_due | formatPrice }}
          </v-list-item-title>
        </v-list-item-action>
      </v-list-item>
    </field-card>
    <field-card
      title="Billing Details"
      subtitle="These details appear on your monthly invoice and determine your tax location & rates."
    >
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            Billing Address
          </v-list-item-title>
          <v-list-item-subtitle style="white-space: pre-line;" v-text="currentAddress" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn outlined @click="formComponent = 'update-billing-details'">
            {{ currentAddress ? 'Update' : 'Add' }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="currentAddress">
        <v-list-item-content>
          <v-list-item-title>
            European VAT Number
          </v-list-item-title>
          <v-list-item-subtitle style="white-space: pre-line;" v-text="currentVatNumber" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn outlined @click="formComponent = 'update-vat-number'">
            {{ currentVatNumber ? 'Update' : 'Add' }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </field-card>
    <field-card
      title="Payment Methods"
      subtitle="Please make sure you have a valid default payment method setup. You can use most credit and debit cards or setup a SEPA direct debit payment."
    >
      <v-alert dense type="info" color="secondary" outlined>
        SEPA payments are currently not available.
      </v-alert>
      <v-list two-line>
        <v-list-item
          v-for="paymentMethod in currentPaymentMethods"
          :key="paymentMethod.id"
          :input-value="paymentMethod.isDefault"
          color="success"
        >
          <v-list-item-content v-if="paymentMethod.card">
            <v-list-item-title>
              {{ paymentMethod.card.funding | capitalizeFirstLetter }} card ending with {{ paymentMethod.card.last4 }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>
                Added {{ paymentMethod.created | formatUnixDate('DD.MM.YYYY HH:mm') }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="flex-row">
            <template v-if="!paymentMethod.isDefault">
              <v-btn
                text
                small
                color="primary"
                :disabled="!!updatingDefaultPaymentMethod || !!deletingPaymentMethod"
                :loading="updatingDefaultPaymentMethod === paymentMethod.id"
                @click="updateDefaultPaymentMethod(paymentMethod.id)"
              >
                Make Default
              </v-btn>
              <v-btn
                small
                outlined
                :disabled="!!deletingPaymentMethod || !!updatingDefaultPaymentMethod"
                :loading="deletingPaymentMethod === paymentMethod.id"
                @click="deletePaymentMethod(paymentMethod.id)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Remove
              </v-btn>
            </template>
            <v-chip v-else color="success">
              Default Payment
            </v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <template #actions>
        <v-btn
          color="primary"
          outlined
          :disabled="!customer || customer.payment_methods.card.length > 9"
          @click="formComponent = 'add-card'"
        >
          Add new card
        </v-btn>
        <!-- Wait until SEPA is enabled in stripe -->
        <!-- :disabled="!customer || customer.payment_methods.sepa_debit.length > 9" -->

        <v-btn
          color="primary"
          outlined
          disabled
          @click="formComponent = 'add-sepa'"
        >
          Add SEPA account
        </v-btn>
      </template>
    </field-card>
    <field-card
      v-if="customer && customer.invoices"
      title="Invoice History"
    >
      <v-data-table
        :items="customer.invoices"
        :headers="invoiceHeaders"
      >
        <template #[`item.number`]="{ item }">
          <a :href="item.invoice_pdf">{{ item.number }}</a>
        </template>
        <template #[`item.created`]="{ item }">
          {{ item.created | formatDate }}
        </template>
        <template #[`item.total`]="{ item }">
          {{ item.total | formatPrice }}
        </template>
        <template #[`item.status`]="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ item.status | capitalizeFirstLetter }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.payment_intent && ['requires_payment_method', 'requires_action'].includes(item.payment_intent.status)">
                <v-btn
                  small
                  color="success"
                  :disabled="!!payingInvoice"
                  :loading="payingInvoice === item.payment_intent.id"
                  @click="payInvoice(item.payment_intent)"
                >
                  Pay Now
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-data-table>
    </field-card>
    <v-overlay v-if="payingInvoice">
      <card-loader message="Please hold on..." />
    </v-overlay>
    <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
    >
      <component
        :is="formComponent"
        v-if="formComponent"
        @cancel="formComponent = null"
        @done="onUpdateDone"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import StripeElements from '@backend/components/StripeElements'
export default {
  name: 'Billing',
  components: {
    // StripeElements,
    'update-billing-details': () => import('@backend/components/UpdateBillingDetails.vue'),
    'update-vat-number': () => import('@backend/components/UpdateVatNumber.vue'),
    'add-card': () => import('@backend/components/AddCard.vue'),
    'add-sepa': () => import('@backend/components/AddSepa.vue')
  },
  props: {
    customer: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    dialog: false,
    formComponent: null,
    updatingDefaultPaymentMethod: false,
    deletingPaymentMethod: false,
    payingInvoice: false,
    invoiceHeaders: [
      {
        text: 'Invoice No',
        align: 'left',
        value: 'number'
      },
      {
        text: 'Date Issued',
        align: 'left',
        value: 'created'
      },
      {
        text: 'Amount',
        align: 'right',
        value: 'total'
      },
      {
        text: 'Payment Status',
        align: 'right',
        value: 'status'
      }
    ]
  }),
  computed: {
    ...mapGetters(['organization']),
    currentAddress() {
      if (!this.customer || !this.customer.address) {
        return ''
      }
      return `${this.customer.address.line1} ${this.customer.address.line2}
      ${this.customer.address.postal_code} ${this.customer.address.city}/${this.customer.address.state}/${this.customer.address.country}`
    },
    currentVatNumber() {
      if (!this.customer) {
        return ''
      }
      const [taxId] = this.customer.tax_ids.data
      if (!taxId) {
        return ''
      }
      const { value, verification } = taxId
      return `${value}
      ${this.capitalizeFirstLetter(verification.status)}`
    },
    currentPaymentMethods() {
      if (!this.customer || !this.customer.payment_methods) {
        return []
      }
      return Object.values(this.customer.payment_methods).flat().sort((a, b) => {
        return a.created - b.created
      }).map((x) => {
        x.isDefault = this.customer.invoice_settings.default_payment_method === x.id
        return x
      })
    }
  },
  watch: {
    formComponent: {
      handler(val) {
        this.dialog = !!val
      }
    }
  },
  methods: {
    async updateDefaultPaymentMethod(paymentMethodId) {
      if (!confirm('Change default payment method?')) {
        return
      }
      this.updatingDefaultPaymentMethod = paymentMethodId
      await this.$axios.post(`${this.$apiUrl}/stripe/customer`, {
        organizationId: this.organization.id,
        payment_method: paymentMethodId
      })
      this.$emit('refresh')
      this.updatingDefaultPaymentMethod = false
    },
    async deletePaymentMethod(paymentMethodId) {
      if (!confirm('Are you sure you want to delete this payment method?')) {
        return
      }
      this.deletingPaymentMethod = paymentMethodId
      await this.$axios.delete(`${this.$apiUrl}/stripe/payment-method`, {
        data: { paymentMethodId }
      })
      this.$emit('refresh')
      this.deletingPaymentMethod = false
    },
    async payInvoice(paymentIntent) {
      const { id, status } = paymentIntent
      let clientSecret = paymentIntent.client_secret
      this.payingInvoice = id
      if (status === 'requires_payment_method') {
        const defaultPaymentMethod = this.customer.invoice_settings.default_payment_method
        if (!defaultPaymentMethod) {
          this.payingInvoice = false
          return this.$store.dispatch('newSnackbar', { type: 'error', message: 'Please add a valid payment method!' })
        }
        const response = await this.$axios.post(`${this.$apiUrl}/stripe/payment-intent`, {
          paymentIntentId: id,
          paymentIntentData: {
            payment_method: defaultPaymentMethod
          }
        })
        if (response.error) {
          this.payingInvoice = false
          return this.$store.dispatch('newSnackbar', { type: 'error', message: response.error.message })
        }
        clientSecret = response.data.client_secret
      }
      if (clientSecret) {
        const { error } = await this.$stripe.confirmCardPayment(clientSecret)
        if (error) {
          this.payingInvoice = false
          return this.$store.dispatch('newSnackbar', { type: 'error', message: error.message })
        } else {
          this.$store.dispatch('newSnackbar', { type: 'success', message: 'Your payment was successfully processed.' })
          this.$emit('refresh')
          this.payingInvoice = false
        }
      }
      this.payingInvoice = false
    },
    onUpdateDone() {
      this.$emit('refresh')
      this.formComponent = null
    }
  }
}
</script>
